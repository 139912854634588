<template>
  <div>
    <v-dialog
      v-model="resetPasswordDialog"
      persistent
      max-width="500px"
      transition="slide-y-transition"
      @keydown.esc="closeDialog()"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Reset Password for') }} [{{ username }}]
        </v-card-title>
        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('New password')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="form.new_password"
                      :label="$t('New password')"
                      :append-icon="show1 ? 'mdil-eye' : 'mdil-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      outlined
                      dense
                      :error-messages="errors"
                      @click:append="show1 = !show1"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('New password')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="form.confirm_password"
                      :label="$t('Confirm Password')"
                      :append-icon="show2 ? 'mdil-eye' : 'mdil-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      outlined
                      dense
                      :error-messages="errors"
                      @click:append="show2 = !show2"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
              >
                <v-icon left>
                  mdil-check
                </v-icon>
                <span class="pe-2">
                  {{ $t('Save') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    resetPasswordDialog: { type: Boolean, default: false },
    username: { type: String, required: true },
    id: { type: Number, required: true }
  },

  data() {
    return {
      form: {},
      show1: false,
      show2: false,
    }
  },

  methods: {
    submit() {
      if (this.form.new_password !== this.form.confirm_password) {
        this.$_notify('Confirm password not match with new password', 'error')
        return
      }

      axios.put(`user/${this.id}/reset_password/`, this.form).then(() => {
        this.$_notify('Password reset successfully')
        this.closeDialog()
      })
    },

    closeDialog() {
      this.form = {}
      this.$refs.theForm.reset()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
