<template>
  <div>
    <v-dialog
      v-model="setPermissionDialog"
      persistent
      max-width="500px"
      scrollable
      transition="slide-y-transition"
      @keydown.esc="closeDialog()"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Set Permission To') }} [{{ username }}]
        </v-card-title>

        <v-card-text v-if="setPermissionDialog">
          <v-treeview
            v-model="user_permissions"
            dense
            selectable
            open-on-click
            selected-color="primary"
            :items="allPermissions"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </v-btn>

          <v-btn
            color="primary"
            depressed
            small
            :disabled="user_permissions.length < 1"
            @click="handleSubmit"
          >
            <v-icon left>
              mdil-check
            </v-icon>
            <span class="pe-2">
              {{ $t('Save') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    setPermissionDialog: { type: Boolean, default: false },
    userPermissions: { type: Array, required: true },
    allPermissions: { type: Array, required: true },
    username: { type: String, required: true },
    id: { type: Number, required: true }
  },

  data() {
    return {
      user_permissions: []
    }
  },

  watch: {
    setPermissionDialog: {
      immediate: false,
      handler() {
        if (this.setPermissionDialog) {
          this.user_permissions = []
          this.userPermissions.forEach(p => {
            this.user_permissions.push(p.id)
          })
        }
      }
    }
  },

  methods: {
    handleSubmit() {
      axios.put(`user/${this.id}/set_permissions/`, { user_permissions: this.user_permissions }).then(() => {
        this.$_notify('Saved successfully')
        this.$emit('close')
      })
    },
  }
}
</script>

<style lang="scss" scoped></style>
